import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["title", "alt"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "myRef",
    class: _normalizeClass(_ctx.$style.bookInfo),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.thumb, _ctx.$style.infoColumn])
    }, [
      _withDirectives(_createElementVNode("img", {
        ref: "thumbRef",
        title: _ctx.title,
        alt: _ctx.title
      }, null, 8, _hoisted_1), [
        [_vShow, _ctx.isThumbLoaded]
      ]),
      (!_ctx.isThumbLoaded)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, "読み込み中"))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.title, _ctx.$style.infoColumn])
    }, _toDisplayString(_ctx.title), 3),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.author, _ctx.$style.infoColumn])
    }, _toDisplayString(_ctx.author), 3),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.star, _ctx.$style.infoColumn])
    }, _toDisplayString(_ctx.star), 3),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.finishDate, _ctx.$style.infoColumn])
    }, "読了日" + _toDisplayString(_ctx.finishDate), 3)
  ], 2))
}