import { computed, InjectionKey, reactive, ref } from "vue";
import axios from 'axios';
import { getIsbn10 } from "@/util/utility";
import { BookDataForReadLog } from "api-interface";

export type BookInfo = BookDataForReadLog & {
    isbn10: string;
}
type FilterInfo = {
    finishYear?: number;
    genre?: string;
    star?: (targetStarNum: number) => boolean;//引数にスター数を渡すとtrue/falseが返るfunction
    keyword?: string;
}
export default function useData() {
    const isLoaded = ref(false);
    const stateMessage = ref('');
    const state = reactive({
        bookInfos : [] as BookInfo[],
        filterInfo: {} as FilterInfo,
    });

    const updateBookInfo = (payload: BookDataForReadLog[]) => {
        const books = payload.map(book => {
            const isbn10 = getIsbn10(book.isbn);
            return Object.assign({}, book, {
                isbn10,
            });
        });
        
        state.bookInfos = books;
    }

    const load = async() => {
        stateMessage.value = '読み込み中・・・';
        try {
            const response = await axios.get('api/readlog');
            const data = response.data as BookDataForReadLog[];
            stateMessage.value = '読み込み完了。データ表示中・・・';
            updateBookInfo(data);
            isLoaded.value = true;
            stateMessage.value = '';
        } catch(e) {
            console.warn(e);
            stateMessage.value = '読み込みに失敗しました。';
        }
    }

    const finishYearList = computed(() => {
        //読了年のリストを返す
        const list = [] as number[];
        state.bookInfos.forEach(function(info){
            if (!info.done_date) {
                return;
            }
            const yearStr = info.done_date.substring(0, 4);
            const year = parseInt(yearStr);
            if(!isNaN(year) && !list.includes(year)){
                list.push(year);
            }
        });
        list.sort((a, b) => b - a);
        return list;
    });

    const genreList = computed(() => {
        //ジャンルのリストを返す
        const list = [] as string[];
        state.bookInfos.forEach(function(info){
            info.genres.forEach(function(genre){
                if(!list.includes(genre)){
                    list.push(genre);
                }
            });
        });
        list.sort();
        return list;
    });

    const setFilterFinishYear = (finishYear: number | undefined) => {
        state.filterInfo.finishYear = finishYear;
    }
    const setFilterGenre = (genre: string | undefined) => {
        state.filterInfo.genre = genre;
    }
    const setFilterStar = (starFunc?: (a: number) => boolean) => {
        state.filterInfo.star = starFunc;
    }
    const setFilterKeyword = (keyword: string | undefined) => {
        state.filterInfo.keyword = keyword;
    }

    const bookInfos = computed(() => {
        let list = state.bookInfos;
        if(state.filterInfo.finishYear) {
            //読了年でフィルタ
            list = list.filter(function(item){
                return item.done_date?.startsWith(state.filterInfo.finishYear + '');
            })
        }
        if(state.filterInfo.genre) {
            //ジャンルでフィルタ
            list = list.filter(function(item){
                return item.genres.some(function(genre){
                    return genre === state.filterInfo.genre;
                })
            })
        }
        if(state.filterInfo.star) {
            //星でフィルタ
            const starFormula = state.filterInfo.star;
            list = list.filter(function(item){
                const ret = starFormula(item.rate ?? 0);
                return ret;
            });
        }
        if(state.filterInfo.keyword) {
            //キーワードでフィルタ
            const keywords = state.filterInfo.keyword.split(/\s|\u{3000}/g);
            list = list.filter(function(item){
                return keywords.every(function(keyword){
                    //タイトル
                    if(item.title.indexOf(keyword) !== -1) return true;
                    //著者
                    const author = item.author.replace(/\s|\u{3000}/g, '');
                    if(author.indexOf(keyword) !== -1) return true;
                    //感想
                    //if(item.think.indexOf(keyword) !== -1) return true;
                    return false;
                });
            });
        }
        return list;
    });

    return {
        isLoaded,
        stateMessage,
        bookInfos,
        finishYearList,
        genreList,
        load,
        setFilterFinishYear,
        setFilterGenre,
        setFilterStar,
        setFilterKeyword,
    }
}

export type DataStore = ReturnType<typeof useData>;
export const DataStoreKey: InjectionKey<DataStore> = Symbol('DataStore');
