
import { DataStoreKey } from '@/store/useData';
import { convertStar } from '@/util/utility';
import { computed, defineComponent, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue';

const starOptionList = [
    {
        label: 'と等しい',
        func: function(baseStarNum: number, targetStarNum: number){return targetStarNum==baseStarNum},
    },
    {
        label: '以上',
        func: function(baseStarNum: number, targetStarNum: number){return targetStarNum>=baseStarNum},
    },
    {
        label: '以下',
        func: function(baseStarNum: number, targetStarNum: number){return targetStarNum<=baseStarNum},
    },
];

export default defineComponent({
    setup() {
        const dataStore = inject(DataStoreKey);
        const finishYear = ref<number>();
        const finishYears = computed(() => dataStore?.finishYearList.value);
        const genre = ref<string>();
        const genreList = computed(() => dataStore?.genreList.value);
        const star = ref<number>();
        const starList = computed(() => {
            const list = [] as {value: number; label: string}[];
            for(let i = 1; i <= 5; i++) {
                const starStr = convertStar(i);
                list.push({
                    value: i,
                    label: starStr,
                });
            }
            return list;
        });
        const starOption = ref<string>('と等しい');
        const keyword = ref('');

        watch(() => finishYear.value, (val) => {
            dataStore?.setFilterFinishYear(val);
        });

        watch(() => genre.value, (val) => {
            dataStore?.setFilterGenre(val);
        });

        watch(() => [star.value, starOption.value], () => {
            if (!star.value) {
                dataStore?.setFilterStar(undefined);
                return;
            }
            const hit = starOptionList.find(item => item.label === starOption.value);
            if (!hit) return;
            const baseNum = star.value;
            const func = (starNum: number) => {
                return hit.func(baseNum, starNum);
            }
            dataStore?.setFilterStar(func);
        });

        watch(() => keyword.value, (val) => {
            if (val.length === 0) {
                dataStore?.setFilterKeyword(undefined);
            } else {
                dataStore?.setFilterKeyword(val);
            }
        });

        const clear = () => {
            finishYear.value = undefined;
            genre.value = undefined;
            star.value = undefined;
            keyword.value = '';
        }

        return {
            finishYears,
            finishYear,
            genre,
            genreList,
            star,
            starList,
            starOption,
            starOptionList,
            keyword,
            clear,
        }
    }
});
