
import { computed, defineComponent, onMounted, PropType, ref, onBeforeUnmount, watch } from 'vue';
import { BookInfo } from '@/store/useData';
import { convertStar } from '@/util/utility';

export default defineComponent({
    emits: ['click'],
    props: {
        bookInfo: {
            type: Object as PropType<BookInfo>,
            required: true,
        },
        // 親表示領域のtop
        parentViewTop: {
            type: Number,
            required: true,
        },
        // 親表示領域のheight
        parentViewHeight: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const myRef = ref<HTMLElement>();
        const thumbRef = ref<HTMLImageElement>();
        const isInShowArea = ref(false);  // 表示領域内にいるか
        const isThumbLoaded = ref(false);

        /**
         * 表示領域内にいるかどうか判定する
         */
        const judgeShowArea = () => {
            if (!myRef.value) {
                return;
            }
            const rect = myRef.value.getBoundingClientRect();
            // 表示領域内にいるか判定
            if(rect.top < 0){
                //上に消えている場合
                isInShowArea.value = false;
            } else if(rect.top > props.parentViewTop + props.parentViewHeight) {
                //下に消えている場合
                isInShowArea.value = false;
            } else {
                isInShowArea.value = true;
            }
        }

        onMounted(() => {
            if (!myRef.value) {
                return;
            }
            judgeShowArea();
            window.addEventListener('resize', judgeShowArea, false);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', judgeShowArea);
        });

        watch(() => [props.parentViewTop, props.parentViewHeight], () => {
            judgeShowArea();
        }, { immediate: true });

        const title = computed(() => props.bookInfo.title);
        const author = computed(() => props.bookInfo.author);
        const star = computed(() => {
            return convertStar(props.bookInfo.rate ?? 0);
        });
        const finishDate = computed(() => props.bookInfo.done_date);

        const loadImage = () => {
            if (isInShowArea.value) {
                if (thumbRef.value) {
                    thumbRef.value.crossOrigin = 'anonymous';
                    thumbRef.value.src = `https://images-na.ssl-images-amazon.com/images/P/${props.bookInfo.isbn10}.09.LZZZZZZZ.jpg`
                    thumbRef.value.onerror = (err) => {
                        console.warn('img LoadErr', err);
                    }
                    thumbRef.value.onload = () => {
                        isThumbLoaded.value = true;
                    }
                }
            }
        }

        watch(() => [props.bookInfo, isInShowArea.value], () => {
            loadImage();
        }, { immediate: true });


        return {
            myRef,
            thumbRef,
            title,
            author,
            star,
            finishDate,
            isThumbLoaded,
        }
    }
});
