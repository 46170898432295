import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

app.directive('scroll', {
    mounted(el, binding) {
        const f = function (evt: any) {
            if (binding.value(evt, el)) {
              el.removeEventListener('scroll', f)
            }
          }
          el.addEventListener('scroll', f)      
    }
});

app.mount('#app')
