
import { convertStar, getIsbn10 } from "@/util/utility";
import axios from "axios";
import { computed, defineComponent, ref, watch } from "vue";
import { BookDataForDetail } from 'api-interface';
import { marked } from "marked";

export default defineComponent({
    props: {
        bookId: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const detail = ref<BookDataForDetail>();

        watch(() => props.bookId, async() => {
            const response = await axios.get(`api/get_detail?id=${props.bookId}`);
            detail.value = response.data as BookDataForDetail;
        }, { immediate: true });

        const isbn10 = computed(() => {
            if (!detail.value) return null;
            return getIsbn10(detail.value.isbn);
        })

        const thumbUrl = computed(() => {
            return `https://images-na.ssl-images-amazon.com/images/P/${isbn10.value}.09.LZZZZZZZ.jpg`;
        })

        const amazonUrl = computed(() => {
            if (!detail.value) return null;
            const associate = detail.value.amazon_associate_id ? `${detail.value.amazon_associate_id}/` : '';
            return `http://www.amazon.co.jp/dp/${isbn10.value}/${associate}ref=nosim`;
        })

        const star = computed(() => {
            if (!detail.value) return '';
            return convertStar(detail.value.rate ?? 0);
        })

        const think = computed(() => {
            const think = detail.value?.think ?? '';
            const html = marked(think);
            return html;
        })

        const hide = () => {
            emit('close');
        };

        return {
            detail,
            thumbUrl,
            amazonUrl,
            star,
            hide,
            think,
        }
    },
});
