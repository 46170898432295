import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_menu = _resolveComponent("filter-menu")!
  const _component_book_list = _resolveComponent("book-list")!
  const _component_book_detail = _resolveComponent("book-detail")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.contents, _ctx.showFilter ? '' : _ctx.$style.closeFilter])
  }, [
    (!_ctx.isLoaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.loading)
        }, _toDisplayString(_ctx.stateMessage), 3))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.filterArea)
          }, [
            _createVNode(_component_filter_menu),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.openCloseBtn),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFilter && _ctx.toggleFilter(...args)))
            }, [
              (_ctx.showFilter)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "▼"))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, "▲"))
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.bookListArea)
          }, [
            _createVNode(_component_book_list, { onClick: _ctx.showDetail }, null, 8, ["onClick"])
          ], 2)
        ], 64)),
    (_ctx.showDetailBookId)
      ? (_openBlock(), _createBlock(_component_book_detail, {
          key: 2,
          bookId: _ctx.showDetailBookId,
          onClose: _ctx.hideDetail
        }, null, 8, ["bookId", "onClose"]))
      : _createCommentVNode("", true)
  ], 2))
}