
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue';
import useData, { BookInfo, DataStoreKey } from '@/store/useData';
import BookList from './components/BookList.vue';
import BookDetail from './components/BookDetail.vue';
import FilterMenu from './components/FilterMenu.vue';

export default defineComponent({
    name: 'App',
    components: {
        BookList,
        BookDetail,
        FilterMenu,
    },
    setup() {
        const dataHook = useData();
        provide(DataStoreKey, dataHook);

        const showDetailBookId = ref<string>();
        const showFilter = ref(false);

        onMounted(() => {
            dataHook.load();
        });

        const isLoaded = computed(() => {
            return dataHook.isLoaded.value;
        });

        const stateMessage = computed(() => dataHook.stateMessage.value);

        const showDetail = (item: BookInfo) => {
            showDetailBookId.value = item.id;
        }

        const hideDetail = () => {
            showDetailBookId.value = undefined;
        }

        const toggleFilter = () => {
            showFilter.value = !showFilter.value;
        }

        return {
            isLoaded,
            stateMessage,
            showDetail,
            hideDetail,
            showDetailBookId,
            showFilter,
            toggleFilter,
        }
    }
});
