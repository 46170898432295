
import { BookInfo, DataStoreKey } from '@/store/useData';
import { computed, defineComponent, inject, onMounted, onUnmounted, provide, reactive, ref, watch } from 'vue';
import BookItem from './BookItem.vue';

export default defineComponent({
    components: { BookItem },
    emits: {
        click: (item: BookInfo) => { return true },
    },
    setup(props, { emit }) {
        const myRef = ref<HTMLElement>();
        const dataStore = inject(DataStoreKey);
        const scrollPositionState = reactive({
            myTop: 0,   //BookListのページ内TOP座標
            myHeight: 0,  //BookListの高さ
            scrollY: 0
        });

        onMounted(() => {
            window.addEventListener('resize', setScreenSize, false);
            if (!myRef.value) {
                return;
            }
            setScreenSize();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', setScreenSize);
        });

        watch(() => dataStore?.bookInfos.value, () => {
            if (!myRef.value) {
                return;
            }
            myRef.value.scrollTop = 0;
        })
        const handleScroll = (evt: any, el: HTMLElement) => {
            scrollPositionState.scrollY = el.scrollTop;
            scrollPositionState.myTop = el.getBoundingClientRect().top;
            scrollPositionState.myHeight = el.getBoundingClientRect().height;
            return false;
        };
        const myViewTop = computed(() => {
            const val = scrollPositionState.myTop + scrollPositionState.scrollY;
            return val;
        });

        const bookInfos = computed(() => {
            return dataStore?.bookInfos.value;
        });
        const setScreenSize = () => {
            if (!myRef.value) return;
            scrollPositionState.myTop = myRef.value.getBoundingClientRect().top;
            scrollPositionState.myHeight = myRef.value.getBoundingClientRect().height;
        }
        const showDetail = (item: BookInfo) => {
            emit('click', item);
        }
        const myHeight = computed(()=> scrollPositionState.myHeight);

        return {
            myRef,
            handleScroll,
            myHeight,
            bookInfos,
            showDetail,
            myViewTop,
        }
    }
});
